import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useQueryParam, StringParam } from "use-query-params"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../../../layouts'
import { Canon, Body, SizedText } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import ExternalLink from '../../../components/elements/externalLink'

const TipBlockContent = ({title, description, children,}) => {
  return (
    <>
      <div css={{
        width: `100%`,
      }}> 
          {children}
      </div>
      <div css={{padding: `1rem 1.5rem 2rem 1.5rem`}}>
        <h2 css={{color: `#182852`, marginBottom: `0`}}>{title}</h2> 
        <Body>{description}</Body>
      </div>
    </>
  )
}

const TipBlock = ({ url, title, description, children, external }) => {

  const blockStyles = {
    backgroundColor: `#fff`,
    boxShadow: `0 1px 2px rgba(0,0,0,0.15)`,
    textDecoration: `none`,
    transition: `margin 0.5s, background-color 0.5s`,
    margin: `0.5rem`,
    '&:hover': {
      margin: `0`,
      backgroundColor: `#fff`
    },
    '.img': {
      boxShadow: `0 0 200px rgba(0,0,0,0.9) inset`,
    }
  }


  return (

    <>
      {external
        ? <ExternalLink to={url} styles={{...blockStyles}}><TipBlockContent title={title} description={description}>{children}</TipBlockContent></ExternalLink>
        : <Link to={url} css={{...blockStyles}}><TipBlockContent title={title} description={description}>{children}</TipBlockContent></Link>
      }
    </>
  )
}



const SmallBusinessResourcesPage = ({ data }) => {


  return (
    <Layout isSubMenuOpen={false} openAccountLink={true}>

<Grid.FullWidthSection styles={{
        overflow: `hidden`,
        alignItems: `center`,
        padding: `6rem 0`,
      }}>
        <Grid.Block start='2' span='12'>
          <h1 css={{fontFamily: `Proxima-nova`, textAlign: `center`, marginBottom: `4rem`}}>
            <SizedText as="span" smallSize="2.027" largeSize="3.653">Small Business Resources</SizedText>
          </h1>
          <div css={{
            display: `grid`,
            gridRowGap: `2rem`,
            '@media (min-width: 680px)': { 
              gridTemplateColumns: `1fr 1fr`,
              padding: `0 2rem`,
              gridGap: `1.5rem`
            },
            '@media (min-width: 1200px)': { 
              gridTemplateColumns: `1fr 1fr 1fr 1fr`,
            },
            '@media (min-width: 1800px)': { 
              gridGap: `3rem`
            },
          }}>
<TipBlock 
              url="https://www.georgiasbdc.org/"
              external={true}
              title="UGA Small Business Development Center"
              description="Activating your card is easy with the TCB Digital Banking App."
            >
              <StaticImage aspectRatio={16 / 9}
                alt=""
                src="../../images/resources/small-business/uga-sbdc.png" />
            </TipBlock>

            <TipBlock 
              url="https://www.georgia.org/"
              external={true}
              title="Georgia Department of Economic Development"
              description="Learn how to change your debit card PIN right from the TCB Digital Banking App."
            >
              <StaticImage aspectRatio={16 / 9}
                alt=""
                src="../../images/resources/small-business/ga-development.png" />
            </TipBlock>

            <TipBlock 
              url="https://www.score.org/us-small-business-administration-sba"
              external={true}
              title="US Small Business Administration SCORE"
              description=" Learn how to temporarily increase your debit spening limit right from the App. "
            >
              <StaticImage aspectRatio={16 / 9}
                alt=""
                src="../../images/resources/small-business/sba-score.png" />
            </TipBlock>
            
            <TipBlock 
              url="https://www.rhbcchamber.org/"
              external={true}
              title="Richmond Hill Chamber of Commerce"
              description=" Learn how to set a travel notification from the TCB Digital Banking App."
            >
              <StaticImage aspectRatio={16 / 9}
                alt=""
                src="../../images/resources/small-business/rhbccc.png" />
            </TipBlock>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}


export default SmallBusinessResourcesPage